.imgCont {
    height: 70vh;
    overflow: hidden;
    display: block;
    margin-left: auto;
    margin-right: auto;
    width: 100%;
}

.topImg {
    width: 100%;
}

.mainCont {
    display: flex;
    padding-top: 5vh;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    letter-spacing: 1.52px;
}

.textCont {
    width: 60%;
    padding: 3vh;
}
.textCont3 {
    width: 100%;
    padding: 3vh;
}

.textCont2 {
    display: flex;
    justify-content: flex-start;
    text-align: start;
    flex-wrap: wrap;
    padding: 3vh;
    width: 60%;
    padding-top: 3vh;
}

.textBox {
    display: block;
}

.contact-form {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    border-radius: 5px;
    padding: 10px 15px 10px 15px;
    background-color: rgb(240, 240, 240);
    width: 30%
}

.contact-control {
    height: 15vh;
}

.formText {
    margin: 15px 0 15px 0;
}

#button {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 5vh;
    width: 30%;
    font-size: 3vh;
}

.theMap {
    margin: 7vh 0 7vh 0;
    width: 60%;
    height: 55vh;
}
.tables{
    width: 100%;
}
@media screen and (max-width: 980px) {
    .textCont, .textCont2, .contact-form {
        width: 95%;
    }
    .theMap {
        height: 40vh;
        width: 90%;
    }
    .imgCont {
        height: 35vh;
    }
}