.main {
    display: flex;
    justify-content: center;
}
.mainContainer {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 85%;
    box-shadow: 0px 50px 15px 1px rgb(179, 179, 179);
}


.carousel-caption {
    display: flex;
    justify-content: center;
    top: 60%;
    transform: translateY(-50%);
    color: black;
}
.cIItem {
    display: flex;
    height: 80vh;
    /* width: 100%; */
    align-items: center;
    justify-content: center;
    align-items: center;
    overflow: hidden;
}
/* .cIItem.active.carousel-item{
    display: flex;
    align-items: center;
    justify-content: center;
    align-items: center;
} */

#img {
    /* height: 80vh; */
}

.carouselItem {
    text-align: center;
    width: 100%;
    color: white;
    text-align: center;
    padding: 20px;
    margin: 0px;
    border-radius: 3px;
    background-color: rgba(0, 0, 0, 0.829);
}
.question {
    border: none;
    background-color: white;
    padding: .5vh;
    margin: 1.5vh 0 1.5vh 0;
    transition: .5s;
    font-size: 2.5vh;
}
.hide {
    display: none;
    transition: .5s;

}
.theMap{ 
    margin: 7vh 0 7vh 0;
    width: 60%; 
    height: 55vh;
} 

.mapDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.homeText {
    margin-top: 10vh;
    /* font-size: 2rem; */
    width: 75%;
    letter-spacing: 1.52px;
} 


.homeText p {
    font-size: 1.2rem;
}
.textCont3 {
    display: flex;
    flex-direction: column;
    justify-content: flex-start;
    align-items: flex-start;
    text-align: start;
    width: 60%;
    padding-top: 3vh;
}
@media screen and (max-width: 980px){
    .homeText {
        font-size: 1.5rem;
        width: 85%
    }
    .homeText p {
        font-size: 1rem;
    }
    #img {
        height: 40vh;
    }
    .cIItem {
        height: 40vh;
    }
    .mainContainer {
        width: 100%;
    }
    .carousel-caption {
        display: flex;
        justify-content: center;
        top: 55%;
        transform: translateY(-50%);
        color: rgb(0, 0, 0);
    }
    .carouselItem{
        font-size: 20px;
    }

}

@media screen and (max-height: 400px){
    .cIItem {
        height: 90vh;
        overflow: show;
    }
    #img {
        height: 90vh;
    }
}
