* {
    padding: 0;
    margin: 0;
}

.mainDiv {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
}

.mainDiv2 {
    display: flex;
    flex-direction: column;
    align-items: center;
    height: 100vh;
    width: 100%;
}

.subDiv {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    width: 80%;
}

.cards {
    background-color: rgb(61, 61, 61);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, .8);
    padding: 0;
    width: 33.3%;
}
.cards2 {
    background-color: rgb(61, 61, 61);
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    border: 1px solid rgba(0, 0, 0, .8);
    height: 25%;
    padding: 0;
    width: 33.3%;
}

.items {
    color: white;
    font-size: 80%;
}

.paragraph {
    margin: 0px;
}
.paragraph2 {
    color: white;
    font-size: 85%;
    margin: 5px;
}

.form {
    align-items: center;
    justify-items: center;
    margin: 21.45vh;
}

.button, .password {
    display: block;
    padding: 2vh;
}

.tables {
    width: 99%;
    font-size: 1.7vh;
}

.texts {
    width: 50%
}


@media screen and (max-width: 980px){
    .tables{
        width: 100%;
    }
    .form{
        width: 70%;
    }
    .main2{
        display: flex;
        flex-direction: column;
        align-items: center;
    }
}