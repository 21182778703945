
.mainBox {
    background-color: rgb(27, 27, 27);
    display: flex;
    justify-content: center;
    align-items: center;
    height: 20vh;
    width: 85%;
}

.textCont {
    display: flex;
    flex-direction: column;
}

.picCont {
    display: flex;
    justify-content: space-around;
}

#pic {
    width: 85%;
    height: 40vh;
}
.mainCont2 {
   width: 35%;
   color: honeydew;
}

.mainCont3 {
    display: flex;
    justify-content: center;
}

.mainCont2 p {
    font-size: 1.8vh;
    letter-spacing: 1.52px;
    padding:0;
}

@media screen and (max-width: 980px){
    .mainCont2 {
        width: 60%;
    }
    .mainBox{
        width: 100%;
    }
    #pic {
        height: 25vh;
    }
}
