
.contain {
    display: flex;
    justify-content: center;
}
.contain2 {
    width: 85%;
    background: rgb(27, 27, 27);
    display: flex;
    justify-content: space-between;
    height: 12vh;
    font-family: 22px scheme;
    text-transform: uppercase;
    color: #fff8f0;
}
.contact {
    height: 7vh;
    text-align: center;
}
.titles {
    display: flex;
    align-items: center;
    text-align: start;
    padding-left: 2%;
    width: 30%;
    font-weight: 400;
    font-style: normal;
    font-size: 85%;
    letter-spacing: 1.52px;
}

.link1:hover {
    text-decoration:none;
}

.headers {
    font-size: 3vh;
}

.contact.btn.btn-light {
    height: 60px;
}
.links {
    display: flex;
    justify-content: space-around;
    align-items: center;
    width: 50%;
    
}

.link1 {
    text-decoration: none;
    color: #fff8f0;
}

.link {
    text-decoration: none;
    color: #fff8f0;
}

.link:hover {
    text-decoration: none;
    color: #fff8f0;
}

.link2 {
    text-decoration: none;
    color: rgb(88, 88, 88);
}

.link2:hover {
    text-decoration: none;
    color:rgb(88, 88, 88);
}

.header {
    font-size: 3vh;
}

.header:hover {
    text-decoration: none;
    color:#fff8f0;
}

.text {
    font-size: 2vh;
    text-decoration: none;
}
.text2 {
    font-size: 2vh;
    text-decoration: none;
}

.text:hover {
    color: #9b9999;
    transition: .3s;
}

.drop {
    display: none;
}

.dropMenu {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    background-color: gray;
    width: 2vw;
}

.drop-down {
    color: white;
}

.contactText {
    font-size: 100%;
}

@media screen and (max-width: 1600px){
  
    .header {
        font-size: 2.5vh;
    }
    
    /* .text:hover {
        font-size: 2vh;
    }     */
    .contact {
        width: 100%;
    }
    .contact2 {
        font-size: 20px;
    }
    .contact.btn.btn-light {
        height: 50px;
    }
}
@media screen and (max-width: 1200px){
    .drop {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
    }
    
    .links {
        display: none;
    }
    .link {
        color: rgb(88, 88, 88);
    }
    .titles {
        width: 50%
    }
    .contact {
        width: 95%
    }
    .dropMenu {
        width: 5vw;
    }
  
}
@media screen and (max-width: 980px){
    .drop {
        display: flex;
        justify-content: center;
        align-items: center;
        width: 30%;
    }
    .contain2 {
        width: 100%;
    }
    .header {
        font-size: 2vh;
    }
    .links {
        display: none;
    }
    .link {
        color: rgb(88, 88, 88);
    }
    .titles {
        width: 50%
    }
    .contact {
        width: 100%
    }
    .contact.btn.btn-light {
        height: 35px;
    }
}
